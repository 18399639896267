































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from "../../../mixins/input-setups"
import Placeholders from "@/mixins/placeholders/placeholders";
import EditorButtonsHelpView from "@/includes/helpViews/editorButtonsHelpView.vue";

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types';
import { UseFields } from "piramis-base-components/src/components/Pi"
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    CenteredColumnLayout,
    PageTitle,
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView,
    }
  }
})
export default class Reputation extends Mixins(UseFields, InputSetups, Placeholders) {
  helpMessageIsOpen = false

  goToRanksHelpMessage(): void {
    this.helpMessageIsOpen = false

    this.$router.push({
      name: 'rank_system',
      params: {
        [EntityTypes.CHAT_ID]: this.$route.params[EntityTypes.CHAT_ID]
      }
    })
  }
}
