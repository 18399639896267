var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('reputation_page_description'))+" "),_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('tab_reputation_mini_help_message'))+" "),_c('span',{staticClass:"cursor-pointer underline",on:{"click":function($event){_vm.helpMessageIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])])]},proxy:true},{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{attrs:{"title":_vm.$t('field_tab_reputation_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'use_karma',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.use_karma)?_c('nested-content',[_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'reputation_min_interval',
              'step': _vm.StepUnit.Second,
              'hasAnchor': true,
            }
          }}}),_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'chat_reputation_min_interval',
              'step': _vm.StepUnit.Second,
              'hasAnchor': true,
            }
          }}}),_c('chips-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'karma_words' ,
              'hasAnchor': true,
            }
          }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'karma_message',
              'buttonsHelpView': _vm.EditorButtonsHelpView,
              'targetToUpload': function () { return _vm.groupUploadTarget; },
              'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
              'availableButtonsTypes': _vm.defaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
            },
          }}})],1):_vm._e()],1),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'use_anti_karma',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.use_anti_karma)?_c('nested-content',[_c('chips-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'anti_karma_words',
              'hasAnchor': true,
            }
          }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'anti_karma_message',
              'buttonsHelpView': _vm.EditorButtonsHelpView,
              'targetToUpload': function () { return _vm.groupUploadTarget; },
              'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
              'availableButtonsTypes': _vm.defaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
            },
          }}})],1):_vm._e()],1)],1),_c('a-modal',{staticClass:"reputation-description-wrapper",attrs:{"centered":"","title":_vm.$t('tab_reputation'),"footer":null},model:{value:(_vm.helpMessageIsOpen),callback:function ($$v) {_vm.helpMessageIsOpen=$$v},expression:"helpMessageIsOpen"}},[_c('div',{staticClass:"flex flex-col text-lg px-4"},[_c('p',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.$t('tab_reputation_help_message_1'))}}),_c('span',{staticClass:"mb-2 text-primary underline hover:cursor-pointer",on:{"click":_vm.goToRanksHelpMessage}},[_vm._v(" "+_vm._s(_vm.$t('tab_reputation_help_message_more_about_ranks'))+" ")]),_c('p',{staticClass:"mb-2",domProps:{"innerHTML":_vm._s(_vm.$t('tab_reputation_help_message_2'))}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }